
(function(window, document, $, undefined){

    'use strict';
    window.menu = {};

    menu.init = function() {
        menu.slideOut();
    }

    menu.slideOut =  function() {

        var slideout = new Slideout({
            'panel': document.getElementById('panel'),
            'menu': document.getElementById('menu'),
            'padding': 256,
            'tolerance': 70,
            'side': 'right'
        });

        // Toggle button
        document.querySelector('.toggle-button').addEventListener('click', function() {
            slideout.toggle();
        });

        console.log('menu loaded');
    }

    $(document).on( 'ready', menu.init );


})(window, document, jQuery);