(function(window, document, $, undefined){

    'use strict';
    window.script = {};

    script.init = function() {

        script.highlight();
        script.highlightCopy();
    }

    script.highlight = function() {
        hljs.initHighlightingOnLoad();
    }

    script.highlightCopy = function() {

        function fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            textArea.style.position="fixed";  //avoid scrolling to bottom
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        }
        function copyTextToClipboard(text) {
            if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(function() {
                console.log('Async: Copying to clipboard was successful!');
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });
        }

        $('button').on('click', function() {
            var buttonID = $(this).attr('data-snippet-button');
            var html = $( '[data-snippet-id="' + buttonID + '"]').text();
            copyTextToClipboard( html );
        });

    }



    $(document).on( 'ready', script.init );



})(window, document, jQuery);