import { gsap } from "gsap";

(function(window, document, $, undefined){

    'use strict';
    window.darkmode = {};

    darkmode.init = function() {
        darkmode.class();
        darkmode.toggleAnimation();
    }

    darkmode.class =  function() {

        const btn = document.querySelector(".btn-toggle");

        const currentTheme = localStorage.getItem("theme");
        if (currentTheme == "dark") {
            document.body.classList.toggle("dark-theme");
        } else if (currentTheme == "light") {
            document.body.classList.toggle("light-theme");
        }

        btn.addEventListener("click", function () {

            if (document.body.classList.toggle("dark-theme")) {
                var theme = 'dark';
                document.body.classList.remove('light-theme');
            } else {
                var theme = 'light';
                document.body.classList.remove('dark-theme');
            }

            document.cookie = "theme=" + theme + "; path=/";
        });

    }

    darkmode.toggleAnimation =  function() {

        const btn = document.querySelector(".btn-toggle");

        // Loading State
        if (btn.classList.contains('toggled')) {

            gsap.set('.crescent', {
                x: 10,
                y: -10,
                opacity: 0
            });

        } else {

            gsap.set('.crescent', {
                x: 0,
                y: 0,
                opacity: 1
            });
            gsap.set('.sun-light', {
                opacity: 0
            });

        }

        // Button Toggle State
        btn.addEventListener("click", function (e) {
            e.preventDefault();

            let tl = gsap.timeline();

            if (btn.classList.contains('toggled')) {

                tl
                    .to(".crescent", { x: 0, y: 0, opacity: 1, duration: .2, delay: 0, ease: "power2.inOut", }, 0)
                    .to(".crescent", { duration: .2, delay: 0, ease: "power2.inOut", }, .2)
                    .to(".sun .circle", { backgroundColor: "#3c3f41", duration: .1, delay: 0, ease: "power2.inOut", }, 0)
                    .to(".sun-light", { opacity: 0, duration: .4, delay: 0, ease: "power2.inOut", }, 0);

                btn.classList.remove("toggled");

            } else {

                tl
                    .to(".crescent", { x: 10, y: -10, duration: .2, delay: 0, ease: "power2.inOut", }, 0)
                    .to(".sun .circle", { backgroundColor: "white", duration: .1, delay: 0, ease: "power2.inOut", }, 0)
                    .to(".crescent", { duration: .2, delay: 0, opacity: 0, ease: "power2.inOut", }, .2)
                    .to(".sun-light", { opacity: 1, duration: .4, delay: .2, ease: "power2.inOut", }, 0);

                btn.classList.add("toggled");

            }

        });

    }

    $(document).on( 'ready', darkmode.init );

})(window, document, jQuery);