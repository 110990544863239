<template>
  {{ dropdown }}

  <!--  <input v-model="inputValue" @keyup="inputChange">-->
  <button @click="updateCodeEditor">
    Output: {{ title }}...
  </button>

  <button @click="updateCode()">
    Output: {{ title }}...
  </button>

  <button @click="updateTemplate">
    HTML
  </button>

  <select v-model="testValue">
    <option disabled value="">Please select one</option>
    <option>Apple</option>
    <option>Banana</option>
    <option>Cherry</option>
  </select>
  new drop down: {{ dropdown }}

</template>

<script>

import {ref, inject} from "vue"

export default {
  data() {
    return {
      testValue: 'this is a test'
    }
  },
  name: "ChildComponent",
  inject:['globalVariable'],
  props: [
    'title',
    'codeEditor'
  ],
  methods: {

    updateCodeEditor() {
      //this.$emit('codeEditor')
      console.log('emitted')
      let $output = this.testValue;

      if (this.testValue === 'Apple') {
        $output = '<div></div>';
      } else if (this.testValue === 'Banana') {
        $output = '<div></div><div></div><div></div>';
      } else if (this.testValue === 'Cherry') {
        $output = '<div></div><div></div><div></div><div></div><div></div>';
      }

      this.$emit('updateCodeEditor', $output);
    }
  },
  //emits:["newCode"],
  emits:["codeEditor"],
  setup(props, context) {

    let name = "John";
    let message = "Doe";

    const dropdown = inject("dropdown");
    console.log('.dropdown' + dropdown)

    const inputValue = ref('')

    /*const updateCode = ()=> {
      context.emit("newCode", props.title);
    };*/

    const updateTemplate = ()=> {
      context.emit("newCode", myTemplate);
    };

    const myTemplate = `
   <div class="template">
      <h1>Hello, ${name}!</h1>
      <p>${message}</p>
      ${dropdown}
      dropdown
   </div>
`;


    return {
      inputValue,
      updateTemplate,
      dropdown
    };
  }
};


</script>

