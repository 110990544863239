
<template>
  <Codemirror
      v-model:value="codeEditor"
      :options="cmOptions"
      border
      ref="cmRef"
      height="200"
      width="600"
      @change="onChange"
      @input="onInput"
      @ready="onReady"
  >
  </Codemirror>

  <ButtonCounter title="testy2" :code="codeEditor" @updateCodeEditor="updateCodeEditor" />
<!--  <ButtonCounter title="testy!!3" @newCode="changeCode" @codeEditor="t21323" />-->

<!--  <DropdownSelect :dropdown="dropdown"/>
 dropdown here: {{ dropdown }}-->
</template>
<script>
import { ref, provide, onMounted, onUnmounted } from "vue"
import "codemirror/mode/javascript/javascript.js"
import Codemirror from "codemirror-editor-vue3"
import ButtonCounter from './ButtonCounter.vue'
import DropdownSelect from './DropdownSelect.vue'


export default {
  provide:{
    globalVariable: 123
  },
  data() {
    return {
      codeEditor: 'codeEditor field77',
      dropdown: '123',
    }
  },
  methods: {

    updateCodeEditor(code) {
      this.codeEditor = code
    },
    receiveEmit() {
      alert('Hello World!');
    }
  },
  components: { Codemirror, ButtonCounter/*, DropdownSelect */},
  props: ['title', 'code'],
  emits: ['code', 'codeEditor'],
  setup(props) {

    const dropdown = ref('Banana');
    provide("dropdown", dropdown);

    const code = ref(
        `var i = 0;
for (; i < 9; i++) {
    console.log(i);
    // more statementsss
}
`
    )
    const changeCode = (newCode) => {
      //code.value = props.title;
      code.value = newCode;
    };

    const cmRef = ref()
    const cmOptions = {
      mode: "text/javascript"
    }
    const onChange = (val, cm) => {
      console.log(val)
      console.log(cm.getValue())
    }

    const onInput = (val) => {
      console.log(val)
    }

    const onReady = (cm) => {
      console.log(cm.focus())
    }

    onMounted(() => {
      setTimeout(() => {
        cmRef.value?.refresh()
      }, 1000)

      /*setTimeout(() => {
        cmRef.value?.resize(300, 200)
      }, 2000)*/

      setTimeout(() => {
        cmRef.value?.cminstance.isClean()
      }, 3000)
    })

    onUnmounted(() => {
      cmRef.value?.destroy()
    })


    return {
      //store,
      code,
      cmRef,
      cmOptions,
      onChange,
      onInput,
      onReady,
      changeCode
    }
  }
}
</script>