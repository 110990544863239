<template>
  <!--  <input v-model="inputValue" @keyup="inputChange">-->
  <!--  <label for="fruitSelect">Select a Fruit:</label>
    <select id="fruitSelect" v-model="selectedFruit">
      <option value="apple">Apple</option>
      <option value="banana">Banana</option>
      <option value="cherry">Cherry</option>
    </select>-->
<form>
  <h2>Select</h2>
  <select v-model="dropdown">
    <option disabled value="">Please select one</option>
    <option>Apple</option>
    <option>Banana</option>
    <option>Cherry</option>
  </select>
  <span>Selected: {{ dropdown }}</span>
</form>
</template>

<script>

import {ref, inject} from "vue"

export default {
  name: "DropdownSelect",
  props: [
    'dropdown',
  ],

  //emits:["newCode"],
  setup(props, context) {

    const dropdown = inject("dropdown");

    //const dropdown = ref('Banana');
    const updateTemplate = ()=> {
      context.emit("newCode", myTemplate);
    };

    return {
      updateTemplate,
      dropdown,
    };
  }
};


</script>

